import React from 'react';
import $ from 'jquery';
import moment from 'moment';

class DatePicker extends React.Component {
    dateRangeRef = React.createRef()
    
    handleOnChange = (date) => {
        this.setState({
            date: date,
        })
        if (this.props.onChange) {
            this.props.onChange(date);
        }
    }

    constructor(props) {
        super(props);
        const date = this.props.date === undefined ? moment().subtract(7, 'days') : this.props.date;
        this.state = {
            date: date
        }
    }

    componentDidMount() {
        $(this.dateRangeRef.current).daterangepicker({
            startDate: this.state.date,
            locale: {
               direction: 'ltr',
               format: moment.localeData().longDateFormat('L'),
               separator: ' - ',
               applyLabel: 'Appliquer', // 'Apply',
               cancelLabel: 'Annuler', // 'Cancel',
               weekLabel: 'S', // 'W',
               customRangeLabel: 'Choisir une période', // 'Custom Range',
               daysOfWeek: moment.weekdaysMin(),
               monthNames: moment.monthsShort(),
               firstDay: moment.localeData().firstDayOfWeek()
           },
           singleDatePicker: true,
        }, this.handleOnChange);
    }

    render() {
        return (
            <input
                ref={ this.dateRangeRef }
                className={ this.props.className }
                type="text"/>
        );
    }
}

export default DatePicker;