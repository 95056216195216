import React from 'react';
import $ from 'jquery';
import moment from 'moment';

class DateRangePicker extends React.Component {
    dateRangeRef = React.createRef()
    
    handleOnChange = (start, stop) => {
        this.setState({
            start: start,
            stop: stop,
        })
        if (this.props.onChange) {
            this.props.onChange(start, stop);
        }
    }

    constructor(props) {
        super(props);
        const start = this.props.start === undefined ? moment().subtract(7, 'days') : this.props.start;
        const stop = this.props.stop === undefined ? moment() : this.props.stop;
        this.state = {
            start: start,
            stop: stop
        }
    }

    componentDidMount() {
        var ranges = {
            'Aujourd\'hui': [moment(), moment()],
            'Hier': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Les 7 derniers jours': [moment().subtract(6, 'days'), moment()],
            'Les 30 derniers jours': [moment().subtract(29, 'days'), moment()],
            'Ce mois': [moment().startOf('month'), moment().endOf('month')],
            'Le mois dernier': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        };
        $(this.dateRangeRef.current).daterangepicker({
            startDate: this.state.start,
            endDate: this.state.stop,
            locale: {
               direction: 'ltr',
               format: moment.localeData().longDateFormat('L'),
               separator: ' - ',
               applyLabel: 'Appliquer', // 'Apply',
               cancelLabel: 'Annuler', // 'Cancel',
               weekLabel: 'S', // 'W',
               customRangeLabel: 'Choisir une période', // 'Custom Range',
               daysOfWeek: moment.weekdaysMin(),
               monthNames: moment.monthsShort(),
               firstDay: moment.localeData().firstDayOfWeek()
           },
           ranges: ranges,
        }, this.handleOnChange);
    }

    render() {
        return (
            <input
                ref={ this.dateRangeRef }
                className={ this.props.className }
                type="text"/>
        );
    }
}

export default DateRangePicker;