import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/fr';
import { withRouter } from "react-router";
import 'popper.js';
import 'bootstrap/dist/js/bootstrap';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faAlignJustify, faArrowLeft, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import equal from 'fast-deep-equal/react';

import Navbar from '../Navbar';
import ReleveModal from '../ReleveModal';
import Graphique from '../Graphique';
import DataModal from '../DataModal';
import DatePicker from '../DatePicker';
import DateRangePicker from '../DateRangePicker';
import Chronologic from '../Chronologic';
import Overview from '../Overview';
import Tooltip from '../Tooltip';
import IndicatorsTableV2 from '../IndicatorsTableV2';
import Footer from '../Footer'
import history from '../../history';

import 'daterangepicker';

import { getAPI, getCookie } from '../../utils';

class PoolPage extends React.Component {

    constructor(props) {
        super(props);
        let now = moment();
        this.state = {
            sheets: [],
            currentSheet: undefined,
            sheetErrorMsg: undefined,
            sideMenu: 'Chronologic',
            pool: undefined,
            pools: [],
            poolFilter: undefined,
            dateFilter: undefined,
            levelFilter: undefined,
            start: now.clone().subtract(7, 'days'),
            stop: now.clone(),
            currentDate: now.clone()
        }
        this.sanitaryNotebookId = undefined;
        this.overviewCache = {};
        this.refMenuBar = React.createRef();
        this.refDateRangeSelector = React.createRef();
        this.getPool = this.getPool.bind(this);
        moment.locale('fr');
        this.retrievePools();
    }

    componentDidMount = () => {
        this._mounted = true;
        this.getPool();
    }

    componentDidUpdate = (prev) => {
        if (!equal(this.props.pools, prev.pools))
        {
            this.getPool();
        }
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    retrievePools = () => {
        if (getCookie('access') !== undefined) {
            const poolId = this.findPoolId();
            getAPI(this.props.host, `/api/v2/projects/${ poolId }`, (err, data) => {
                if (err === false) {
                    if (data !== undefined && data.status === 200) {
                        let pool = data.data;
                        let pools = []
                        const start = moment().subtract(1, 'month')
                        const stop = moment()
                        getAPI(this.props.host, `/api/project/${ poolId }/record/?type=water_quality`, (err, data) => {
                            if (err === false) {
                                pools.push(pool)
                                this.setState({
                                    pools: pools
                                })
                                if (data !== undefined && data.status === 200) {
                                    if (data.data.length !== 0) {
                                        const id = data.data[0].id;
                                        const endpoint = `/api/project/${ poolId }/record/${ id }/data/${ start.format('Y-MM-DD') }/${ stop.format('Y-MM-DD') }/`;
                                        getAPI(this.props.host, endpoint, (err, data) => {
                                        if (err === false && data && data.status === 200) {
                                            pool.status = this.getPoolWorstStatus(pool.pools, data.data);
                                            this.setState({
                                                pools: pools,
                                            })
                                        }
                                        })                      
                                    }
                                }
                            }
                        });
                    }
                }
            })
        }
    }

    getPool() {
        if (this.props.pools === undefined || this.props.match.params.poolId === undefined)
            return undefined;
        const id = parseInt(this.props.match.params.poolId);
        const pool = this.props.pools.find((pool) => pool.id === id)
        this.setState({
            pool: pool
        }, () => {
            this.getReports();
        })
    }

    sideMenuContentFilterSheets = (sheets) => {
        let poolFilter = undefined;
        if (this.state.poolFilter !== 'all')
            poolFilter = this.state.poolFilter;
        let dateFilter = undefined;
        if (this.state.dateFilter !== undefined && this.state.dateFilter !== 'all')
            dateFilter = moment(this.state.dateFilter)
        let levelFilter = undefined;
        if (this.state.levelFilter !== undefined && this.state.levelFilter !== 'all')
            levelFilter = parseInt(this.state.levelFilter);
        const results = sheets.filter(sheet => {
            let poolFilterStatus = true;
            let dateFilterStatus = true;
            let levelFilterStatus = true;
            if (poolFilter !== undefined && sheet.data.pool_name && sheet.data.pool_name.value !== poolFilter) {
                poolFilterStatus = false;
            }
            if (dateFilter !== undefined && sheet.datetime && dateFilter.isSame(sheet.datetime, 'day') === false) {
                dateFilterStatus = false;
            }
            if (levelFilter !== undefined && sheet.level !== levelFilter) {
                levelFilterStatus = false;
            }
            return poolFilterStatus && dateFilterStatus && levelFilterStatus;
        });
        if (sheets !== undefined && sheets.length !== 0 && results.length === 0) {
            return 'No results found';
        }
        return results;
    }

    getSanitaryNotebookId() {
        if (this.sanitaryNotebookId) {
            return this.sanitaryNotebookId;
        } else {
            let pool_id = this.findPoolId();
            getAPI(this.props.host, `/api/project/${ pool_id }/notebook/`, (err, response) => {
                if (err === false) {
                    response.data.forEach(notebook => {
                        if (notebook.title === "Carnet sanitaire") {
                            this.sanitaryNotebookId = notebook.id;
                            this.setState({});
                        }
                    })
                }
            })
        }
        return undefined;
    }

    getOverview() {
        let date = this.state.currentDate.format('YYYY-MM-DD');
        if (date in this.overviewCache) {
            return this.overviewCache[date];
        } else {
            let pool_id = this.findPoolId();
            let sanitary_notebook_id = this.getSanitaryNotebookId()
            if (sanitary_notebook_id === undefined) {
                return undefined;
            }
            let endpoint = `/api/project/${ pool_id }/notebook/${ sanitary_notebook_id }/overview/${ date }`;
            getAPI(this.props.host, endpoint, (err, response) => {
                if (err === false) {
                    if (response.status === 200) {
                        this.overviewCache[date] = response.data;
                        this.setState({});
                    }
                }
            })
        }
        return undefined;
    }

    sideMenuContent = () => {
        if (this.state.sheetErrorMsg) {
            return <div>{ this.state.sheetErrorMsg }</div>
        }
        const pool = this.findPool();
        if (this.state.sideMenu === 'Graphique') {
            return <div className='scrollable-y'>
                <Graphique
                    sheets={ this.state.sheets }
                    pool={ pool }
                    poolFilter={ this.state.poolFilter }
                    onGraphClicked={(pk) => {
                        const sheet = this.state.sheets.find(sheet => sheet.pk === pk);
                        if (sheet) {
                            this.setState({
                                currentSheet: sheet,
                            })
                            $('#releveMod').modal('show');
                        }
                    }}
                    />
            </div>
        }
        if (this.state.sideMenu === 'Chronologic') {
            const sheets = this.sideMenuContentFilterSheets(this.state.sheets);
            if (typeof sheets === 'string') {
                return <p>Pas de résultat pour les filtres choisis. </p>
            }
            return <div className='scrollable-y'><Chronologic sheets={ sheets } onSheetClicked={(sheet) => {
                if (sheet) {
                    this.setState({
                        currentSheet: sheet,
                    })
                    $('#releveMod').modal('show');
                }
            }} /></div>
        }
        if (this.state.sideMenu === 'Overview') {
            return <div className='scrollable-y scrollable-x'>
                <div className='d-flex justify-content-between'>
                    <button className="btn btn-light" onClick={ () => this.setState({ currentDate: this.state.currentDate.subtract(1, 'day') }) }>&lt;</button>
                    <span style={{ fontSize: "20px", fontWeight: "bold" }}>{ this.state.currentDate.format('LL') }</span>
                    <button className="btn btn-light" onClick={ () => this.setState({ currentDate: this.state.currentDate.add(1, 'day') }) }>&gt;</button>
                </div>
                <Overview overview={ this.getOverview() }></Overview>
            </div>;
        }
    }

    sideMenuBarContent = () => {
        if (this.state.sideMenu === 'Graphique') {
            const pool = this.findPool();
            let pools = []
            if (pool && pool.pools && Array.isArray(pool.pools)) {
                pools = pool.pools
            }
            return <div>
                <div className="form-group row">
                    <label htmlFor="date-filter" className="col-sm-2">Date : </label>
                    <DateRangePicker
                        className="form-control col-sm-10"
                        start={ this.state.start }
                        stop={ this.state.stop }
                        onChange={ (start, stop) => this.setState({start: start, stop: stop}, this.getReports) }/>
                </div>
                <div className="form-group row">
                    <label htmlFor="date-filter" className="col-sm-2">Bassin : </label>
                    <select
                        className="select-block form-control col-sm-10"
                        onChange={ (evt) => this.setState({ poolFilter: evt.target.value }) }>
                        <option key='pool-filter-undefined' value={ 'all' }>Tous</option>
                        {
                            pools.map(pool => {
                                return <option key={ `pool-filter-${ pool.id }` } value={ pool.name }>{ pool.name }</option>
                            })
                        }
                    </select>
                </div>
            </div>
        } else if (this.state.sideMenu === 'Chronologic') {
            const pool = this.findPool();
            let pools = []
            if (pool && pool.pools && Array.isArray(pool.pools)) {
                pools = pool.pools
            }
            return <div>
                <div className="row">
                    <label htmlFor="date-filter" className="col-sm-2">Date : </label>
                    <DateRangePicker
                        className="form-control col-sm-10"
                        start={ this.state.start }
                        stop={ this.state.stop }
                        onChange={ (start, stop) => this.setState({start: start, stop: stop}, this.getReports) }/>
                </div>
                <div className="row">
                    <label htmlFor="date-filter" className="col-sm-2">Bassin : </label>
                    <select
                        className="select-block form-control col-sm-10"
                        onChange={ (evt) => this.setState({ poolFilter: evt.target.value }) }>
                        <option key='pool-filter-undefined' value={ 'all' }>Tous</option>
                        {
                            pools.map(pool => {
                                return <option key={ `pool-filter-${ pool.id }` } value={ pool.name }>{ pool.name }</option>
                            })
                        }
                    </select>
                </div>
                <div className="row">
                    <label htmlFor="date-filter" className="col-sm-2">Etat :
                        <Tooltip title="<p class='text-justify text-white'>Un relevé est considéré conforme si toutes les grandeurs sont dans les plages de valeurs conformes avec le code de la santé publique.<br><br>Un relevé est considéré non conforme si au moins une des grandeurs a une valeur non conforme, nécessitant une action corrective.<br><br>Un relevé est considéré critique si au moins une des grandeurs a une valeur critique, nécessitant une évacuation immédiate du bassin.</p>" dataPlacement="bottom">
                            <span><sup>?</sup></span>
                        </Tooltip>
                    </label>
                    <select
                        className="select-block form-control col-sm-10"
                        defaultValue={ this.state.levelFilter }
                        onChange={(evt) => this.setState({ levelFilter: evt.target.value })}>
                        <option key='pool-filter-undefined' value={ 'all' }>Tous</option>
                        <option key='pool-filter-success' value={ '0' }>Conforme</option>
                        <option key='pool-filter-warning' value={ '1' }>Non conforme</option>
                        <option key='pool-filter-danger' value={ '2' }>Critique</option>
                    </select>
                </div>
            </div>
        } else if (this.state.sideMenu === 'Overview') {
            return <div>
                <div className="row">
                    <label htmlFor="date-filter" className="col-sm-2">Date : </label>
                    <DatePicker
                        className="form-control col-sm-10"
                        date={ this.state.currentDate }
                        onChange={ (date) => this.setState({ currentDate: date }, this.getReports) }/>
                </div>
            </div>
        }
        return null;
    }

    setSheetsErrorMessage = (start, stop) => {
        let sheetErrorMsg = `Pas de relevés trouvés entre le ${ moment(start).format('L') } et ${ moment(stop).format('L') }.`
        sheetErrorMsg += ` Si vous pensez qu'il s'agit d'une erreur, merci de contacter les équipes d'Ecotropy`
        this.setState({
            sheetErrorMsg: sheetErrorMsg
        })
    }

    getReports = () => {
        let pool_id = this.findPoolId();
        if (pool_id !== undefined && this._mounted && this.state.start && this.state.stop) {
            this.setState({
                sheets: [],
                currentSheet: undefined,
            })
            getAPI(this.props.host, `/api/project/${ pool_id }/record/?type=water_quality`, (err, response) => {
                if (err === false) {
                    if (response.data.length !== 0 && this._mounted) {
                        const waterRecordId = response.data[0].id;
                        const start = this.state.start.format('Y-MM-DD')
                        const stop = this.state.stop.format('Y-MM-DD')
                        getAPI(this.props.host, `/api/project/${ pool_id }/record/${ waterRecordId }/data/${ start }/${ stop }`, (err, response) => {
                            if (err === false) {
                                if (response.status === 200 && response.data.length !== 0) {
                                    const sheets = response.data;
                                    sheets.sort((a, b) => {
                                        const datetimea = moment(a.datetime);
                                        const datetimeb = moment(b.datetime);
                                        if (datetimea < datetimeb) {
                                            return 1;
                                        } else {
                                            return -1;
                                        }
                                    });
                                    if (this._mounted) {
                                        this.setState({
                                            sheets: sheets,
                                            currentSheet: sheets[0],
                                            sheetErrorMsg: undefined,
                                        })
                                    }
                                } else {
                                    this.setSheetsErrorMessage(this.state.start, this.state.stop)
                                }
                            } else {
                                this.setSheetsErrorMessage(this.state.start, this.state.stop)
                            }
                        })
                    } else {
                        this.setSheetsErrorMessage(this.state.start, this.state.stop)
                    }
                } else {
                    this.setSheetsErrorMessage(this.state.start, this.state.stop)
                }
            })
        }
    }

    findPoolId() {
        return parseInt(this.props.match.params.poolId);
    }

    findPool() {
        let pool = undefined;
        if (this.state.pools) {
            const pool_id = this.findPoolId();
            pool = { ...this.state.pools.find((pool) => pool.id === pool_id) };
        }
        return pool;
    }

    render() {
        const pool = this.findPool();

        if (pool === undefined) {
            return <div></div>
        }

        let lat = pool.latitude;
        if (lat === undefined) {
            lat = 0;
        }

        let long = pool.longitude;
        if (long === undefined) {
            long = 0;
        }

        return (
            <div className="page">
                <Navbar />
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover />
                <div className="row p-3 pt-0">
                    <button className="btn btn-light d-sm-none d-md-block" style={{ width: "100px" }} onClick={() => history.goBack() }>
                        <FontAwesomeIcon icon={ faArrowLeft } fixedWidth />
                    </button>
                    <h1 className="text-center ml-2">{ pool.name }</h1>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <hr />
                        <div>Nombre de bassins : { pool.pools && pool.pools.length } </div>
                        <div>Nombre de circuits de filtration : { pool.loops && pool.loops.length } </div>
                        <div>Surface totale de bassins : { pool.pools && pool.pools.filter(pool => pool.area !== undefined ).reduce((p,v) => p+v.area, 0) } { pool.pools && "m2" }</div>
                        <div>Volume total de bassins : { pool.pools && pool.pools.filter(pool => pool.volume !== undefined ).reduce((p,v) => p+v.volume, 0) } { pool.pools && "m3" }</div>
                        <hr />
                        <br />
                        <IndicatorsTableV2 host={ this.props.host } poolId={ this.findPoolId() } access={ getCookie('access') }/>
                    </div>
                    <div className="col-sm-8">
                        <div className="row">
                            <div className="col-sm-12 col-md-4 row mb-3" style={ {height: "120px"} }>
                                <button
                                    className={ "col-sm-4 btn " + (this.state.sideMenu === 'Chronologic' ? "btn-primary text-white" : "btn-light" )}
                                    onClick={() => this.setState({ sideMenu: 'Chronologic'}) }>
                                        <div>
                                            <FontAwesomeIcon icon={ faAlignJustify } size="3x" fixedWidth/>
                                        </div>
                                        <div className="mt-2">Relevés</div>
                                </button>
                                <button
                                    className={ "col-sm-4 btn " + (this.state.sideMenu === 'Overview' ? "btn-primary text-white" : "btn-light" )}
                                    onClick={() => this.setState({ sideMenu: 'Overview'}) }>
                                        <div>
                                            <FontAwesomeIcon icon={ faFileAlt } size="3x" fixedWidth/>
                                        </div>
                                        <div className="mt-2">Tableaux</div>
                                </button>
                                <button
                                    className={ "col-sm-4 btn " + (this.state.sideMenu === 'Graphique' ? "btn-primary  text-white" : "btn-light") }
                                    onClick={ () => this.setState({ sideMenu: 'Graphique'}) }>
                                        <div>
                                            <FontAwesomeIcon icon={ faChartLine } size="3x" fixedWidth/>
                                        </div>
                                        <div className="mt-2">Graphiques</div>
                                </button>
                            </div>
                            <div className="col-sm-12 col-md-8">
                                { this.sideMenuBarContent() }
                            </div>
                        </div>
                        <hr />
                        { this.sideMenuContent() }
                    </div>
                    {/* <button className="btn fa-button" style={{ backgroundColor: '#1ABC9C', color: 'white' }} onClick={ () => $('#dataMod').modal('show') }>
                        <FontAwesomeIcon icon={ faPlus } />
                    </button> */}
                </div>
                <DataModal
                    id="dataMod"
                    host={ this.props.host }
                    data={ pool }
                    access={ getCookie('access') }
                    projectId={ pool.id }
                    onFormCompleted={() => {
                        this.getReports();
                        toast.success('Le relevés a été créé', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }}>
                </DataModal>

                <ReleveModal id="releveMod" sheet={ this.state.currentSheet } />

                <Footer></Footer>
            </div>
        );
    }
}

export default withRouter(PoolPage);