import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

import Loader from './Loader';

class Chronologic extends React.Component {
    state = {}

    onSheetClicked(sheet) {
        if (this.props.onSheetClicked) {
            this.props.onSheetClicked(sheet);
        }
    }

    getValidationIcon(predicate) {
        if (predicate) {
            return <FontAwesomeIcon icon={ faCheck } size="lg" fixedWidth />;
        }
        return <FontAwesomeIcon icon={ faTimes } size="lg" fixedWidth />;
    }

    getListClassFromDataSheet(sheet) {
        if (sheet.level === 0) {
            return 'list-success';
        } else if (sheet.level === 1) {
            return 'list-warning';
        }
        return 'list-success';
    }

    render() {
        if (this.props.sheets === undefined || this.props.sheets.length === 0) {
            return <Loader />;
        }
        return (
            <ul className="timeline">
                {
                    this.props.sheets && this.props.sheets.map(sheet => {
                        return (
                        <li key={ sheet.pk } className={ this.getListClassFromDataSheet(sheet) } onClick={() => this.onSheetClicked(sheet) }>Qualité d'eau - { moment(sheet.datetime).format('LLL') } { sheet.data && sheet.data.pool_name && <span>{ sheet.data.pool_name.value }</span>}
                                <ul className="innerlist">
                                    {
                                        sheet.data && sheet.data.water_treatment_active && sheet.data.water_treatment_active.value !== undefined &&
                                        <li>
                                            { this.getValidationIcon(sheet.data.water_treatment_active.value >= 0.4 && sheet.data.water_treatment_active.value <= 1.4) }
                                            { sheet.data.water_treatment_active.name }
                                        </li>
                                    }
                                    {
                                        sheet.data && sheet.data.water_treatment_combined && sheet.data.water_treatment_combined.value !== undefined &&
                                        <li>
                                            { this.getValidationIcon(sheet.data.water_treatment_combined.value <= 0.6) }
                                            { "Combiné (chloramines)" }
                                        </li>
                                    }
                                    {
                                        sheet.data && sheet.data.water_pH && sheet.data.water_pH.value !== undefined &&
                                        <li>
                                            { this.getValidationIcon(sheet.data.water_pH.value >= 6.9 && sheet.data.water_pH.value <= 7.7) }
                                            { sheet.data.water_pH.name }
                                        </li>
                                    }
                                    {
                                        sheet.data && sheet.data.water_temperature && sheet.data.water_temperature.value !== undefined &&
                                        <li>
                                            { this.getValidationIcon(sheet.data.water_temperature.value <= 33) }
                                            { sheet.data.water_temperature.name }
                                        </li>
                                    }
                                    {
                                        sheet.data && sheet.data.transparency && sheet.data.transparency.value !== undefined &&
                                        <li>
                                            { this.getValidationIcon(sheet.data.transparency.value === "Bonne") }
                                            { sheet.data.transparency.name }
                                        </li>
                                    }
                                </ul>
                            </li>
                        );
                    })
                }
            </ul>
        );
    }
}

export default Chronologic;