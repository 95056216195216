import L from 'leaflet';

const getMarkerIconFromStatus = (status) => {
    if (status === 'danger' || status === 2) {
        return L.icon({
            iconSize: [40, 40],
            iconUrl: '/danger-marker.png',
        });
    } else if (status === 'warning' || status === 1) {
        return L.icon({
            iconSize: [40, 40],
            iconUrl: '/warning-marker.png',
        });
    } else if (status === 'success' || status === 0) {
        return L.icon({
            iconSize: [40, 40],
            iconUrl: '/success-marker.png',
        });
    }
    return L.icon({
        iconSize: [40, 40],
        iconUrl: '/marker.png',
    });
}

export { getMarkerIconFromStatus };