import React from 'react';
import axios from 'axios';

class LoginPage extends React.Component {
    state = {
        email: '',
        password: '',
        emailError: '',
        passwordError: '',
        formError: '',
    }

    handleReset = (event) => {
        this.setState({
            email: '',
            password: ''
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.props.host) {
            const endpoint = '/api/auth/';
            let status = true;
            let emailError = undefined;
            let passwordError = undefined;

            // TODO: Check email format.
            if (!this.state.email.trim().length) {
                status = false;
                emailError = 'Champ obligatoire.';
            }
            if (!this.state.password.trim().length) {
                status = false;
                passwordError = 'Champ obligatoire.';
            }

            if (status) {
                this.setState({
                    emailError: '',
                    passwordError: '',
                    formError: '',
                })
                
                axios.post(this.props.host + endpoint, {
                    username: this.state.email,
                    password: this.state.password,
                }).then(response => {
                    if (response.status === 200) {
                        if (this.props.onLoginSuccess) {
                            this.props.onLoginSuccess(response.data);
                        }
                    }
                }).catch(error => {
                    this.setState({
                        formError: 'Mauvaise adresse mail ou mot de passe.'
                    })
                    this.handleReset();
                })
            } else {
                this.setState({
                    emailError: emailError,
                    passwordError: passwordError
                })
            }
        }
    }

    //
    // @brief: Handle change on email field.
    //
    handleEmailChange = (event) => {
        const value = event.target.value;
        this.setState({ email: value })
    }

    //
    // @brief: Handle change on password field.
    //
    handlePasswordChange = (event) => {
        const value = event.target.value;
        this.setState({ password: value })
    }

    render() {
        return (
            <div className="login-page">
                <div className="login-wrapper bg-light">

                    <div className="login-logo-wrapper">
                        <img
                            className="login-logo"
                            src={process.env.PUBLIC_URL + '/logo.svg'}
                            alt="Buildsense Logo"/>
                    </div>

                    <form>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Adresse mail"
                            value={ this.state.email }
                            onChange={ this.handleEmailChange } />

                        { this.state.emailError && <small className="text-danger">
                            { this.state.emailError }
                        </small> }

                        <input
                            className="mt-2 form-control"
                            type="password"
                            placeholder="Mot de passe"
                            value={ this.state.password }
                            onChange={ this.handlePasswordChange } />

                        { this.state.passwordError && <small className="text-danger">
                            { this.state.passwordError }
                        </small> }

                        <input
                            onClick={ this.handleSubmit }
                            className="mt-4 form-control btn btn-primary"
                            type="submit"
                            value="Se connecter" />

                        { this.state.formError && <div className="mt-2">
                            <small className="text-center text-danger form-error">
                                { this.state.formError }
                            </small>
                        </div> }

                        <p className="text-center pt-3">
                            <a href={ `${ this.props.host }/account/password_reset/` }>Mot de passe oublié</a>
                            <span> | </span>
                            <a href="https://ecotropy.fr/contact">Contactez-nous</a>
                        </p>
                    </form>
                </div>
            </div>
        );
    }
}

export default LoginPage;