import React from 'react';
import $ from 'jquery';

class Tooltip extends React.Component {
    tooltip = React.createRef();

    componentDidMount() {
        $(this.tooltip.current).tooltip();
    }

    render() {
        return (
            <div ref={ this.tooltip } data-html="true" data-toggle="tooltip" style={{ display: 'inline' }} title={ this.props.title } data-placement={ this.props.dataPlacement }>
                { this.props.children }
            </div>
        );
    }
}

export default Tooltip;