import React from 'react';

class Footer extends React.Component {
    state = {}

    render() {
        return (
           <div className="footer">
             <div className="text-center">
                Site réalisé par <a className="footer-link" href="https://ecotropy.fr">Ecotropy</a>
                <span> | </span>
                <a className="footer-link" href="https://buildsense.fr/mentions">Mentions légales</a>
            </div>
           </div>
        );
    }
}

export default Footer;