import React from 'react';

const ReleveElement = (props) => {
    if (props.title === undefined || props.value === undefined) {
        return <div></div>;
    }
    return (
        <div className={ props.className }>
            <span className="pl-2">{ props.title + ": "} </span>
            <span className="">{ props.value }</span>
        </div>
    )
};

export default ReleveElement;