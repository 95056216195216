import * as d3 from 'd3';

export const createGauge = (selector, gaugeData) => {
    if (selector === undefined || gaugeData === undefined) {
        return;
    }
    d3.select(selector).selectAll('*').remove()
    var graph = d3.select(selector)
        .append("svg")
        .attr("width", "100%")
        .attr("height", 80);
    graph.selectAll("rect") 
        .data(gaugeData.data)
        .enter()
        .append("rect")
        .attr("width", (d, i) => ((d.e-d.s) * 100 / gaugeData.size) + '%')
        .attr("height", 20)
        .attr("x", (d, i) => (d.s * 100 / gaugeData.size) + '%')
        .attr("y", 30)
        .attr("fill", d => d.c)
    graph.selectAll("text")
        .data(gaugeData.data)
        .enter()
        .append("text")
        .text(d => d.e)
        .attr("x", (d, i) => (d.e * 100 / gaugeData.size) + '%')
        .attr("y", 70)
        .attr("text-anchor", (d, i) => {
            if (i === 0) { return "start"; }
            if (i === gaugeData.data.length - 1) { return "end"; }
            return "middle";
        })
        .attr("id", d => `gaugeThreshold${d.e}`)
        .attr("fill", d => d.c)
    graph.append("rect")
        .attr("width", 2)
        .attr("height", 30)
        .attr("x", (gaugeData.gauge * 100 / gaugeData.size) + '%')
        .attr("y", 25)
        .attr("fill", "black")
    graph.append("text")
        .text(gaugeData.gauge.toString())
        .attr("x", (gaugeData.gauge * 100 / gaugeData.size) + '%')
        .attr("y", 17)
        .attr("text-anchor", () => {
            if (gaugeData.gauge <= 5) { return "start"; }
            if (gaugeData.gauge >= gaugeData.size - 5) { return "end"; }
            return "middle";
        })
        .attr("id", "gaugeDescription")
}