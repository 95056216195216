import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

class Overview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getDatetimeDiff(sheet) {
        const a = moment(sheet.current_datetime);
        const b = moment(sheet.previous_datetime);
        const diff = moment.duration(a.diff(b));

        let days = diff.days()
        let hours = diff.hours()
        let minutes = diff.minutes()

        if (days > 0) {
            return `${ days } jours, ${ hours } heures`;
        }
        return `${ hours } heures, ${ minutes } min`;
    }

    render() {
        if (this.props.overview === undefined || this.props.overview.overview === undefined) {
            return <div></div>
        }
        let overview = this.props.overview.overview;
        return <div>
            <div className="mb-3">
                <br />
                <h4 className="mb-3" style={{ color: "#007bff" }}>Qualité de l'eau</h4>
                <div className="w-100">
                    <table className="table w-100 mb-0">
                        <thead>
                            <tr>
                                <th className="text-center" style={{ width: "350px" }}>Bassin</th>
                                <th className="text-center">Heure <br />&nbsp;</th>
                                <th className="text-center">Température <br />de l'air</th>
                                <th className="text-center">Transparence <br />&nbsp;</th>
                                <th className="text-center">Température <br />de l'eau</th>
                                <th className="text-center">pH <br />&nbsp;</th>
                                <th className="text-center">DPD1 <br />&nbsp;</th>
                                <th className="text-center">DPD1+DPD3 <br />&nbsp;</th>
                                <th className="text-center">Stabilisant <br />&nbsp;</th>
                                <th className="text-center">Chlore libre <br />actif</th>
                                <th className="text-center">Combinés <br />(chloramines)</th>
                                <th className="text-center">Anomalies <br />détectées</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                overview.water_quality.map((elem, elem_index) => {
                                    if (elem.sheets.length === 0) {
                                        return <tr key={ "water_quality_" + elem_index }>
                                            <td className="text-center">{ elem.pool.name }</td>
                                            <td colSpan="11" className="text-center">
                                                <i>Pas de relevé sanitaire sur ce bassin</i>
                                            </td>
                                        </tr>
                                    }
                                    return elem.sheets.map((sheet, sheet_index) => {
                                        return <tr key={ "water_quality_" + elem_index + "_sheet_index_" + sheet_index }>
                                            {
                                                sheet_index === 0 && <td className="text-center" rowSpan={ elem.sheets.length }>{ elem.pool.name }</td>
                                            }
                                            <td className="text-center">{ sheet.hour }</td>
                                            <td className="text-center">{ sheet.air_temperature }</td>
                                            <td className="text-center">{ sheet.transparency }</td>
                                            <td className="text-center">{ sheet.water_temperature }</td>
                                            <td className="text-center">{ sheet.water_pH }</td>
                                            <td className="text-center">{ sheet.water_treatment_dpd1 }</td>
                                            <td className="text-center">{ sheet.water_treatment_total_dpd1_dpd3 }</td>
                                            <td className="text-center">{ sheet.water_treatment_stabilizer }</td>
                                            <td className="text-center">{ sheet.water_treatment_active }</td>
                                            <td className="text-center">{ sheet.water_treatment_combined }</td>
                                            {
                                                sheet.anomaly && <td className="text-center"><b>X</b></td>
                                            }
                                            {
                                                !sheet.anomaly && <td></td>
                                            }
                                        </tr>
                                    })
                                })
                            }
                        </tbody>
                    </table>
                </div>

                <h4 className="mt-3 mb-3" style={{ color: "#007bff" }}>Renouvellement d'eau</h4>
                { overview.loop_meter_index.length > 0 && <table className="table table-sm mb-0">
                    <thead>
                        <tr>
                            <th className="text-center" style={{ width: "350px" }}>Circuit</th>
                            <th className="text-center">Compteur</th>
                            <th className="text-center">Heure</th>
                            <th className="text-center">Écart entre relevés</th>
                            <th className="text-center">Index</th>
                            <th className="text-center">Consommation [m<sup>3</sup>]</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            overview.loop_meter_index.map((loop_meter, loop_meter_index) => {
                                return loop_meter.records.map((record, record_index) => {
                                    if (record.sheets.length === 0) {
                                        return <tr key={ "loop_meter_index_" + loop_meter_index }>
                                            <td className="text-center">{ loop_meter.loop.name }</td>
                                            <td className="text-center">{ record.meter.name }</td>
                                            <td colSpan={4} className="text-center">
                                                <i>Pas de relevé sur ce compteur</i>
                                            </td>
                                        </tr>
                                    }
                                    return record.sheets.map((sheet, sheet_index) => {
                                        return <tr key={ "loop_meter_index_" + loop_meter_index }>
                                            {
                                                record_index === 0 && sheet_index === 0 && <td className="text-center" rowSpan={ loop_meter.number_of_lines }>{ loop_meter.loop.name }</td>
                                            }
                                            {
                                                sheet_index === 0 && <td className="text-center" rowSpan={ record.sheets.length }>{ record.meter.name }</td>
                                            }
                                            <td className="text-center">{ sheet.hour }</td>
                                            <td className="text-center">{ this.getDatetimeDiff(sheet) }</td>
                                            <td className="text-center">{ sheet.index }</td>
                                            <td className="text-center">{ sheet.consumption }</td>
                                        </tr>
                                    })
                                })
                            })
                        }
                    </tbody>
                </table>}
                { overview.loop_meter_index.length === 0 && <span class="font-italic">Pas de relevé des compteurs d'eau</span> }

                <br />
                <br />

                <table className="table table-sm mb-0">
                    <thead>
                        <tr>
                            <th className="text-center" style={{ width: "350px" }}>Bassin</th>
                            <th className="text-center">Heure</th>
                            <th className="text-center">Débit [m<sup>3</sup>/h]</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            overview.pool_flowrate.map((pool_flowrate, pool_flowrate_index) => {
                                if (pool_flowrate.sheets.length === 0) {
                                    return <tr key={ "pool_flowrate_" + pool_flowrate_index }>
                                        <td className="text-center">{ pool_flowrate.pool.name }</td>
                                        <td className="text-center" colSpan={2}><i>Pas de relevé de débit sur ce bassin</i></td>
                                    </tr>
                                }
                                return pool_flowrate.sheets.map((sheet, sheet_index) => {
                                    return <tr key={ "pool_flowrate_" + pool_flowrate_index }>
                                        {
                                            pool_flowrate_index === 0 && <td className="text-center" rowSpan={ pool_flowrate.sheets.length }>{ pool_flowrate.pool.name }</td>
                                        }
                                        <td className="text-center">{ sheet.hour }</td>
                                        <td className="text-center">{ sheet.flowrate }</td>
                                    </tr>  
                                })
                            })
                        }
                    </tbody>
                </table>

                <h4 className="mt-3 mb-3" style={{ color: "#007bff" }}>Indicateurs</h4>
                <b className="text-grey">Renouvellement total</b> : 
                {
                    (overview.indicators.water_total !== undefined && overview.indicators.water_total !== null) && <span> { Math.floor(overview.indicators.water_total) } m<sup>3</sup></span>
                }
                {
                    (overview.indicators.water_total === undefined || overview.indicators.water_total === null) && <span> Donnée non disponible</span>
                }
                <br />
                <b className="text-grey">Fréquentation du jour</b> : 
                { 
                    (overview.indicators.attendance !== undefined && overview.indicators.attendance !== null) && <span> { overview.indicators.attendance } baigneurs</span>
                }
                { 
                    (overview.indicators.attendance === undefined || overview.indicators.attendance === null) && <span> Donnée non disponible</span>
                }
                <br />
                <b className="text-grey">Ratio de renouvellement</b> :
                <FontAwesomeIcon
                    data-toggle="tooltip"
                    icon={ faQuestionCircle }
                    fixedWidth
                    title="Le ratio est calculé comme le rapport entre le renouvellement d'eau total dans l'ensemble des circuits et le nombre total de baigneurs."
                />
                {
                    (overview.indicators.water_per_bather !== undefined && overview.indicators.water_per_bather !== null) && <span> { Math.floor(overview.indicators.water_per_bather) } L/baigneur</span>
                }
                {
                    (overview.indicators.water_per_bather === undefined || overview.indicators.water_per_bather === null) && <span> Donnée non disponible</span>
                }
                <br />
                <br />

                <h4 className="mt-3 mb-3" style={{ color: "#007bff" }}>Main courante</h4>
                {
                    overview.events.length > 0 && <table className="table table-sm mb-0">
                        <thead>
                        <tr>
                            <th className="text-center" style={{ width: "350px" }}>Heure</th>
                            <th className="text-center">Type</th>
                            <th className="text-center" style={{ width: "30%" }}>Utilisateur</th>
                            <th className="text-left">Description</th>
                        </tr>
                        </thead>
                        <tbody>
                            {
                                overview.events.map((event, event_idx) => {
                                    return <tr key={ "event_" + event_idx}>
                                        <td className="text-center">{event.hour}</td>
                                        <td className="text-center">{event.title}</td>
                                        <td className="text-center">{event.user.username}</td>
                                        <td className="text-left" dangerouslySetInnerHTML={{__html: event.content }}></td>
                                    </tr>   
                                })
                            }
                        </tbody>
                    </table>
                }
                {
                    overview.events.length === 0 && <span>Aucun événement signalé</span>
                }

                <br />
                <br />

            </div>
        </div>;
    }s
};

export default Overview