import React from 'react';
import $ from 'jquery';


class SpanWithTooltip extends React.Component {
    tooltip = React.createRef();

    componentDidMount() {
        $(this.tooltip.current).tooltip();
    }

    render() {
        return <span ref={ this.tooltip } data-html="true" data-toggle="tooltip" title={ this.props.title } className={ this.props.type !== undefined ? `text-${this.props.type}` : '' }>{ this.props.message }</span>;
    }
}

export default SpanWithTooltip;