import React from 'react';

class Loader extends React.Component {
    state = {}

    render() {
        return (
            <div className="loader"></div>
        );
    }
}

export default Loader;