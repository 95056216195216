import React from 'react';
import Plotly from 'plotly.js-dist';
import Loader from './Loader';

class Graphique extends React.Component {
    data = undefined;

    graphFromKey(layout, key) {
        let graphData = []
        Object.keys(this.data).forEach(pool => {
            const d = this.data[pool];
            const dFiltered = d.filter(e => e.data[key].value );

            if (dFiltered.length > 0) {
                graphData.push({
                    y: dFiltered.map(e => e.data[key].value),
                    x: dFiltered.map(e => e.datetime),
                    mode: 'lines+markers',
                    name: pool,
                });                    
            }
        })
        if (graphData.length > 0) {
            Plotly.newPlot(`${ key }_graph`, graphData, layout);
            document.getElementById(`${key}_graph`).on('plotly_click', (data) => {
                if (data.points.length > 0) {
                    const point = data.points[0];
                    const name = this.props.pool.pools[point.curveNumber];
                    if (this.props.onGraphClicked) {
                        this.props.onGraphClicked(this.data[name][point.pointNumber].pk);
                    }
                }
            });    
        }
    }

    airTemperatureGraph() {
        if (this.props.sheets.length > 0) {
            const key = 'air_temperature';
            this.graphFromKey({
                title: 'Evolution de la température',
                yaxis: {
                    title: '°C'
                },
                shapes: [
                    {
                        type: 'rect',
                        x0: this.props.sheets[0].datetime,
                        y0: 0,
                        x1: this.props.sheets[this.props.sheets.length -1].datetime,
                        y1: 33,
                        fillcolor: '#70d26d',
                        opacity: 0.3,
                        line: {
                            width: 0
                        }
                    },
                    {
                        type: 'rect',
                        x0: this.props.sheets[0].datetime,
                        y0: 33,
                        x1: this.props.sheets[this.props.sheets.length -1].datetime,
                        y1: 36,
                        fillcolor: '#ffd775',
                        opacity: 0.3,
                        line: {
                            width: 0
                        }
                    },
                    {
                        type: 'rect',
                        x0: this.props.sheets[0].datetime,
                        y0: 36,
                        x1: this.props.sheets[this.props.sheets.length -1].datetime,
                        y1: 60,
                        fillcolor: '#ff5e5e',
                        opacity: 0.3,
                        line: {
                            width: 0
                        }
                    },
                ],
            }, key);
        }
    }

    waterTemperatureGraph() {
        if (this.props.sheets.length > 0) {
            const key = 'water_temperature';
            this.graphFromKey({
                title: 'Evolution de la température de l\'eau',
                yaxis: {
                    title: '°C'
                },
                shapes: [
                    {
                        type: 'rect',
                        x0: this.props.sheets[0].datetime,
                        y0: 0,
                        x1: this.props.sheets[this.props.sheets.length -1].datetime,
                        y1: 33,
                        fillcolor: '#70d26d',
                        opacity: 0.3,
                        line: {
                            width: 0
                        }
                    },
                    {
                        type: 'rect',
                        x0: this.props.sheets[0].datetime,
                        y0: 33,
                        x1: this.props.sheets[this.props.sheets.length -1].datetime,
                        y1: 36,
                        fillcolor: '#ffd775',
                        opacity: 0.3,
                        line: {
                            width: 0
                        }
                    },
                    {
                        type: 'rect',
                        x0: this.props.sheets[0].datetime,
                        y0: 36,
                        x1: this.props.sheets[this.props.sheets.length -1].datetime,
                        y1: 60,
                        fillcolor: '#ff5e5e',
                        opacity: 0.3,
                        line: {
                            width: 0
                        }
                    },
                ],
            }, key);
        }
    }

    waterPhGraph() {
        if (this.props.sheets.length > 0) {
            const key = 'water_pH';
            this.graphFromKey({
                title: 'Evolution du ph de l\'eau',
                shapes: [
                    {
                        type: 'rect',
                        x0: this.props.sheets[0].datetime,
                        y0: 0,
                        x1: this.props.sheets[this.props.sheets.length -1].datetime,
                        y1: 5,
                        fillcolor: '#ff5e5e',
                        opacity: 0.3,
                        line: {
                            width: 0
                        }
                    },
                    {
                        type: 'rect',
                        x0: this.props.sheets[0].datetime,
                        y0: 5,
                        x1: this.props.sheets[this.props.sheets.length -1].datetime,
                        y1: 6.9,
                        fillcolor: '#ffd775',
                        opacity: 0.3,
                        line: {
                            width: 0
                        }
                    },
                    {
                        type: 'rect',
                        x0: this.props.sheets[0].datetime,
                        y0: 6.9,
                        x1: this.props.sheets[this.props.sheets.length -1].datetime,
                        y1: 7.7,
                        fillcolor: '#70d26d',
                        opacity: 0.3,
                        line: {
                            width: 0
                        }
                    },
                    {
                        type: 'rect',
                        x0: this.props.sheets[0].datetime,
                        y0: 7.7,
                        x1: this.props.sheets[this.props.sheets.length -1].datetime,
                        y1: 8.5,
                        fillcolor: '#ffd775',
                        opacity: 0.3,
                        line: {
                            width: 0
                        }
                    },
                    {
                        type: 'rect',
                        x0: this.props.sheets[0].datetime,
                        y0: 8.5,
                        x1: this.props.sheets[this.props.sheets.length -1].datetime,
                        y1: 15,
                        fillcolor: '#ff5e5e',
                        opacity: 0.3,
                        line: {
                            width: 0
                        }
                    },
                ],
            }, key);
        }
    }

    activeGraph() {
        if (this.props.sheets.length > 0) {
            this.graphFromKey({
                title: 'Chlore libre actif',
                yaxis: {
                    title: 'mg/L'
                },
                shapes: [
                    {
                        type: 'rect',
                        x0: this.props.sheets[0].datetime,
                        y0: 0,
                        x1: this.props.sheets[this.props.sheets.length -1].datetime,
                        y1: 0.3,
                        fillcolor: '#ff5e5e',
                        opacity: 0.3,
                        line: {
                            width: 0
                        }
                    },
                    {
                        type: 'rect',
                        x0: this.props.sheets[0].datetime,
                        y0: 0.3,
                        x1: this.props.sheets[this.props.sheets.length -1].datetime,
                        y1: 0.4,
                        fillcolor: '#ffd775',
                        opacity: 0.3,
                        line: {
                            width: 0
                        }
                    },
                    {
                        type: 'rect',
                        x0: this.props.sheets[0].datetime,
                        y0: 0.4,
                        x1: this.props.sheets[this.props.sheets.length -1].datetime,
                        y1: 1.4,
                        fillcolor: '#70d26d',
                        opacity: 0.3,
                        line: {
                            width: 0
                        }
                    },
                    {
                        type: 'rect',
                        x0: this.props.sheets[0].datetime,
                        y0: 1.4,
                        x1: this.props.sheets[this.props.sheets.length -1].datetime,
                        y1: 5.4,
                        fillcolor: '#ffd775',
                        opacity: 0.3,
                        line: {
                            width: 0
                        }
                    },
                    {
                        type: 'rect',
                        x0: this.props.sheets[0].datetime,
                        y0: 5.4,
                        x1: this.props.sheets[this.props.sheets.length -1].datetime,
                        y1: 6,
                        fillcolor: '#ff5e5e',
                        opacity: 0.3,
                        line: {
                            width: 0
                        }
                    },
                ], 
            }, 'water_treatment_active');
        }
    }

    combinedGraph() {
        if (this.props.sheets.length > 0) {
            this.graphFromKey({
                title: 'Chlore combiné (chloramines)',
                yaxis: {
                    title: 'mg/L'
                },
                shapes: [
                    {
                        type: 'rect',
                        x0: this.props.sheets[0].datetime,
                        y0: 0,
                        x1: this.props.sheets[this.props.sheets.length -1].datetime,
                        y1: 0.6,
                        fillcolor: '#70d26d',
                        opacity: 0.3,
                        line: {
                            width: 0
                        }
                    },
                    {
                        type: 'rect',
                        x0: this.props.sheets[0].datetime,
                        y0: 0.6,
                        x1: this.props.sheets[this.props.sheets.length -1].datetime,
                        y1: 0.8,
                        fillcolor: '#ffd775',
                        opacity: 0.3,
                        line: {
                            width: 0
                        }
                    },
                    {
                        type: 'rect',
                        x0: this.props.sheets[0].datetime,
                        y0: 0.8,
                        x1: this.props.sheets[this.props.sheets.length -1].datetime,
                        y1: 1.5,
                        fillcolor: '#ff5e5e',
                        opacity: 0.3,
                        line: {
                            width: 0
                        }
                    },
                ],
            }, 'water_treatment_combined');
        }
    }

    parseData() {
        if (this.props.pool !== undefined && this.props.sheets !== undefined) {
            let data = {}
            if (this.props.pool.pools) {
                this.props.pool.pools.forEach(pool => data[pool.name] = []);
            }
            if (this.props.sheets) {
                this.props.sheets.forEach(sheet => {
                    if (sheet.data !== undefined && sheet.data.pool_name !== undefined) {
                        const name = sheet.data.pool_name.value;
                        if (this.props.poolFilter === undefined || this.props.poolFilter === 'all' || name === this.props.poolFilter) {
                            if (name !== undefined && name in data) {
                                // Do NOT push item in list if datetime is the same as last element.
                                if (data[name].length === 0 || data[name][data[name].length - 1].datetime !== sheet.datetime) {
                                    data[name].push(sheet);
                                }
                            }
                        }
                    }
                });    
            }
            this.data = data;
        }
    }

    componentDidMount() {
        this.parseData();
        this.airTemperatureGraph();
        this.waterTemperatureGraph();
        this.waterPhGraph();
        this.activeGraph();
        this.combinedGraph();
    }

    componentDidUpdate() {
        this.parseData();
        this.airTemperatureGraph();
        this.waterTemperatureGraph();
        this.waterPhGraph();
        this.activeGraph();
        this.combinedGraph();
    }

    render() {
        if (this.props.sheets === undefined || this.props.sheets.length === 0) {
            return <Loader />;
        }
        return (
            <React.Fragment>
                <div id='air_temperature_graph'></div>
                <div id='water_temperature_graph'></div>
                <div id='water_pH_graph'></div>
                <div id='water_treatment_active_graph'></div>
                <div id='water_treatment_combined_graph'></div>
            </React.Fragment>
        );
    }
}

export default Graphique;