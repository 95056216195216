import React from 'react';
import { withRouter } from 'react-router-dom';
import history from '../history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import $ from 'jquery';

import { delCookie } from '../utils';

class Navbar extends React.Component {
    logOutButton = React.createRef()

    componentDidMount() {
        $(this.logOutButton.current).tooltip();
    }

    render() {
        return (
            <React.Fragment>
                <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Légende de la carte</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <p><span className="text-grey">Gris</span> : Informations manquantes</p>
                                <p><span className="text-success">Vert</span> : Tous les relevés sont conformes</p>
                                <p><span className="text-warning">Orange</span> : Quelques relevés non conformes constatés</p>
                                <p><span className="text-danger">Rouge</span> : Des relevés critiques ont été constatés</p>
                            </div>
                        </div>
                    </div>
                </div>

                <nav className="navbar navbar-expand-lg fixed-top navbar-light navbar-color">
                    {/* {
                        this.props.location.pathname !== '/' && <button style={{ width: '60px' }} className="btn btn-light" onClick={() => {
                            history.goBack();
                        }}><FontAwesomeIcon icon={ faArrowLeft } fixedWidth /></button>
                    }

                    {
                        this.props.location.pathname === '/' && <div style={{ width: '70px' }}></div>
                    } */}

                    <a className="navbar-brand" href="/">
                        <img
                            src={ process.env.PUBLIC_URL + '/ecotropy_logo.png' }
                            width="100"
                            alt="Logo" />
                    </a>

                    <div className="collapse d-none d-md-block navbar-collapse">
                        <a className="app-title" href="/">Carnet sanitaire dématérialisé</a>
                    </div>

                    {
                        this.props.showDataLegend && <button className="btn btn-light mr-2" data-toggle="modal" data-target="#exampleModal">
                            <FontAwesomeIcon icon={ faQuestionCircle } fixedWidth />
                        </button>
                    }
                    <button
                        ref={ this.logOutButton }
                        className="btn btn-light"
                        onClick={() => {
                            let should_logout = window.confirm("Voulez-vous vous déconnecter ?");
                            if (should_logout) {
                                delCookie('access');
                                delCookie('refresh');
                                history.push('/login');
                                window.location.reload(false);    
                            }
                        }}
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Déconnexion">
                        <FontAwesomeIcon icon={ faSignOutAlt } fixedWidth />
                    </button>
                </nav>
            </React.Fragment>
        )
    }
}

export default withRouter(Navbar);