import axios from 'axios';
import $ from 'jquery';

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return undefined;
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function delCookie(cname) {
    document.cookie = cname + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
}

const refreshTokens = (host, callback) => {
    const token = getCookie('refresh');
    const url = `${host}/api/auth/refresh/`;
    axios.post(url, {
        refresh: token
    }).then(data => {
        if (data.data !== undefined && data.data.access !== undefined) {
            setCookie('access', data.data.access, 1);
            callback()
        }
    }).catch(() => {
        delCookie('access');
        delCookie('refresh');
        window.location.reload(false);
    });
}

const getAPI = (host, endpoint, callback) => {
    const token = getCookie('access');
    if (token !== undefined) {
        axios({ url: host + endpoint, headers: { 'Authorization': `Bearer ${ token }`}})
            .then(data => callback(false, data))
            .catch(error => {
                if (error.response !== undefined && error.response.status === 401) {
                    refreshTokens(host, () => {
                        axios({ url: host + endpoint, headers: { 'Authorization': `Bearer ${ getCookie('access') }`}})
                            .then(data => callback(false, data))
                            .catch(error => callback(true, error))
                    })
                } else {
                    callback(true, error);
                }
            })
    }
}

const postAPI = (host, endpoint, data, callback) => {
    $.ajax({
        type: "POST",
        dataType: "json",
        url: host + endpoint,
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${ getCookie('access') }`},
        data: data,
        error: (error) => {
            if (error !== undefined && error.status === 401) {
                refreshTokens(host, () => {
                    $.ajax({
                        type: "POST",
                        dataType: "json",
                        url: host + endpoint,
                        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${ getCookie('access') }`},
                        data: data,
                        error: (error) => {
                            callback(true, error.responseJSON);
                        }
                    }).then(data => callback(false, data))
                })
            } else {
                callback(true, error.responseJSON);
            }
        }
    }).then(data => callback(false, data))
}

export { refreshTokens, getAPI, postAPI, getCookie, setCookie, delCookie };