function transparencyValidator(value) {
    if (value === "Bonne") {
        return 'success';
    }
    return 'danger';
}

function airTemperatureValidator(value) {
    if (value <= 33) {
        return 'success';
    } else if (value <= 36) {
        return 'warning';
    }
    return 'danger';
}

function waterTemperatureValidator(value) {
    if (value <= 33) {
        return 'success';
    } else if (value <= 36) {
        return 'warning';
    }
    return 'danger';
}

function waterPhValidator(value) {
    if (value <= 5 || value > 8.5) {
        return 'danger';
    } else if (value <= 6.9 || value > 7.7) {
        return 'warning';
    }
    return 'success';
}

function waterTreatmentActiveValidator(value) {
    if (value <= 0.3 || value > 5.4) {
        return 'danger';
    } else if (value <= 0.4 || value > 1.4) {
        return 'warning';
    }
    return 'success';
}

function waterTreatmentCombinedValidator(value) {
    if (value <= 0.6) {
        return 'success';
    } else if (value <= 0.8) {
        return 'warning';
    }
    return 'danger';
}

export { transparencyValidator, airTemperatureValidator, waterTemperatureValidator, waterPhValidator, waterTreatmentActiveValidator, waterTreatmentCombinedValidator }