import React from 'react';
import './App.css';
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import moment from 'moment';

import LoginPage from './components/pages/LoginPage';
import IndexPage from './components/pages/IndexPage';
import PoolPage from './components/pages/PoolPage';
import history from './history';
import { getAPI, getCookie, setCookie, delCookie } from './utils';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pools: [],
    };
    this.retrievePools();
  }

  // host = 'http://localhost:8000';
  // host = 'https://rc.buildsense.fr';
  host = 'https://buildsense.fr';

  handleLoginSuccess = (tokens) => {
    delCookie('access');
    delCookie('refresh');
    setCookie('access', tokens.access, 1);
    setCookie('refresh', tokens.refresh, 1);
    window.location.reload(false);
  }

  handlePoolSelection = (pool) => {
    history.push(`/pools/${ pool.id }`)
  }

  getIndicatorLast2DaysTest = (pool, data) => {
    //
    // Check records quality (2 days).
    //
    let warning = 0;
    let danger = 0;
    let start = moment().subtract(7, 'days');
    let stop = moment()
    data.forEach(sheet => {
      let sheet_date = moment(sheet.datetime);
      if (sheet_date > start && sheet_date < stop) {
        if (sheet.level === 1) {
          warning += 1;
        } else if (sheet.level === 2) {
          danger += 1;
        }
      }
    })
    if (danger > 0) {
      return "danger";
    }
    if (warning > 0) {
      return "warning";
    }
    return "success";
  }

  getPoolWorstStatus = (pools, data) => {
    let status = 'success';
    let tests = [
      this.getIndicatorLast2DaysTest,
    ];

    if (data === undefined || data.length === 0) {
      return undefined;
    }
    pools.forEach(pool => {
      tests.forEach(test => {
        const s = test(pool, data);
        if ((s === 'danger') || (s === 'warning' && status !== 'danger')) {
          status = s
        }
      })
    })
    return status;
  }

  retrievePools = () => {
    if (getCookie('access') !== undefined) {
      getAPI(this.host, '/api/project/', (err, data) => {
        if (err === false) {
          if (data !== undefined && data.status === 200) {
            let pools = []
            const start = moment().subtract(1, 'month')
            const stop = moment()
            data.data.forEach(pool => {
              getAPI(this.host, `/api/project/${ pool.id }/record/?type=water_quality`, (err, data) => {
                if (err === false) {
                  pools.push(pool)
                  this.setState({
                    pools: pools
                  })
                  if (data !== undefined && data.status === 200) {
                    if (data.data.length !== 0) {
                      const id = data.data[0].id;
                      const endpoint = `/api/project/${ pool.id }/record/${ id }/data/${ start.format('Y-MM-DD') }/${ stop.format('Y-MM-DD') }/`;
                      getAPI(this.host, endpoint, (err, data) => {
                        if (err === false && data && data.status === 200) {
                          pool.status = this.getPoolWorstStatus(pool.pools, data.data);
                          this.setState({
                            pools: pools,
                          })
                        }
                      })                      
                    }
                  }
                }
              });
            })
          }
        }
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/login">
            {
              getCookie('access') === undefined ? <LoginPage host={ this.host } onLoginSuccess={ this.handleLoginSuccess } /> : <Redirect to="/" />
            }
          </Route>
          <Route path="/pools/:poolId">
            {
              getCookie('access') === undefined ? <Redirect to="/login" /> : <PoolPage host={ this.host } pools={ this.state.pools } /> 
            }
          </Route>
          <Route path="/">
            {
              getCookie('access') === undefined ? <Redirect to="/login" /> : <IndexPage host={ this.host } pools={ this.state.pools } handlePoolSelection={ this.handlePoolSelection } />
            }
          </Route>
        </Switch>
      </React.Fragment>
    )
  }
}

export default App;