import React from 'react';
import { createGauge } from '../gauge';

class Gauge extends React.Component {
    state = {}

    componentDidMount() {
        if (this.props.data !== undefined && this.props.data.gauge !== undefined && isNaN(this.props.data.gauge) === false) {
            createGauge(`#${ this.props.id }`, this.props.data);
        }
    }

    componentDidUpdate() {
        if (this.props.data !== undefined && this.props.data.gauge !== undefined && isNaN(this.props.data.gauge) === false) {
            createGauge(`#${ this.props.id }`, this.props.data);
        }
    }

    render() {
        return (
            <div id={ this.props.id } ></div>
        );
    }
}

export default Gauge;