import React from 'react';
import ReleveElement from './ReleveElement';
import Gauge from './Gauge';
import moment from 'moment';

import { transparencyValidator, airTemperatureValidator, waterTemperatureValidator, waterPhValidator, waterTreatmentActiveValidator, waterTreatmentCombinedValidator } from '../validators';


class ReleveModal extends React.Component {
    ok = "#70d26d";
    warning = "#ffd775";
    danger = "#ff5e5e";

    getHumanizeValueByTag = (key) => {
        if (this.props.sheet && this.props.sheet.data) {
            const found = this.props.sheet.data[key];
            if (found) {
                if (found.value === undefined) {
                    return 'Valeur non renseignée';
                }
                const unit = found.unit === 'degC' || found.unit === 'degree' ? '°C' : found.unit;
                if (unit === undefined) {
                    return `${ found.value }`;
                }
                return `${ found.value } ${ unit }`;
            }
        }
        return 'Valeur non renseignée';
    }

    getValueByTag = (key) => {
        if (this.props.sheet && this.props.sheet.data) {
            const found = this.props.sheet.data[key];
            if (found) {
                return found.value;
            }
        }
        return NaN;
    }

    getClassByTag = (key) => {
        const validators = {
            'transparency': transparencyValidator,
            'air_temperature': airTemperatureValidator,
            'water_temperature': waterTemperatureValidator,
            'water_pH': waterPhValidator,
            'water_treatment_active': waterTreatmentActiveValidator,
            'water_treatment_combined': waterTreatmentCombinedValidator,
        }
        if (this.props.sheet && this.props.sheet.data) {
            if (this.props.sheet.data[key] === undefined || this.props.sheet.data[key].value === undefined) {
                return '';
            }
            return validators[key](this.props.sheet.data[key].value);
        }
        return '';
    }

    getNameByTag = (key) => {
        if (this.props.sheet && this.props.sheet.data) {
            const elem = this.props.sheet.data[key];
            if (elem) {
                return elem.name
            }
        }
        return undefined;
    }

    render() {
        return (
            <div id={ this.props.id } className="modal bd-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                { this.props.sheet && this.props.sheet.data && this.props.sheet.data.pool_name && this.props.sheet.data.pool_name.value }
                                { this.props.sheet && this.props.sheet.datetime && moment(this.props.sheet.datetime).format(' (DD/MM/Y HH:mm)') }
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {
                                this.props.sheet === undefined && <p>Pas de données.</p>
                            }
                            <ReleveElement
                                title={ this.getNameByTag('transparency') }
                                className={ this.getClassByTag('transparency') }
                                value={ this.getHumanizeValueByTag('transparency') }/>
                            <ReleveElement
                                title={ this.getNameByTag('air_temperature') }
                                className={ this.getClassByTag('air_temperature') }
                                value={ this.getHumanizeValueByTag('air_temperature') } />
                            <ReleveElement
                                title={ this.getNameByTag('water_temperature') }
                                className={ this.getClassByTag('water_temperature') }
                                value={ this.getHumanizeValueByTag('water_temperature') } />
                            <Gauge id="waterTemperature" data={{
                                size: 80,
                                data: [
                                    {s: 0, e: 36, c: this.ok},
                                    {s: 36, e: 80, c: this.warning}
                                ],
                                gauge: this.getValueByTag('water_temperature')
                            }} />
                            <hr />

                            <ReleveElement
                                title={ this.getNameByTag('water_pH') }
                                className={ this.getClassByTag('water_pH') }
                                value={ this.getHumanizeValueByTag('water_pH') } />
                            <Gauge id="waterPh" data={{
                                size: 15,
                                data: [
                                    {s: 0, e: 5, c: this.danger},
                                    {s: 5, e: 6.9, c: this.warning},
                                    {s: 6.9, e: 7.7, c: this.ok},
                                    {s: 7.7, e: 8.5, c: this.warning},
                                    {s: 8.5, e: 15, c: this.danger},
                                ],
                                gauge: this.getValueByTag('water_pH')
                            }} />

                            <hr/>

                            <ReleveElement
                                title={ this.getNameByTag('water_treatment_stabilizer') }
                                value={ this.getHumanizeValueByTag('water_treatment_stabilizer') } />
                            <ReleveElement
                                title={ this.getNameByTag('water_treatment_dpd1') }
                                value={ this.getHumanizeValueByTag('water_treatment_dpd1') } />
                            <ReleveElement
                                title={ this.getNameByTag('water_treatment_total_dpd1_dpd3') }
                                value={ this.getHumanizeValueByTag('water_treatment_total_dpd1_dpd3') } />

                            <hr />

                            <ReleveElement
                                title={ this.getNameByTag('water_treatment_active') }
                                className={ this.getClassByTag('water_treatment_active') }
                                value={ this.getHumanizeValueByTag('water_treatment_active') } />
                            <Gauge id="waterTreatmentActive" data={{
                                size: 6,
                                data: [
                                    {s: 0, e: 0.3, c: this.danger},
                                    {s: 0.3, e: 0.4, c: this.warning},
                                    {s: 0.4, e: 1.4, c: this.ok},
                                    {s: 1.4, e: 5.4, c: this.warning},
                                    {s: 5.4, e: 6, c: this.danger},
                                ],
                                gauge: this.getValueByTag('water_treatment_active')
                            }} />
                            <hr />

                            <ReleveElement
                                title={ "Combiné (chloramines)" }
                                className={ this.getClassByTag('water_treatment_combined') }
                                value={ this.getHumanizeValueByTag('water_treatment_combined') } />
                            <Gauge id="waterTreatmentCombined" data={{
                                size: 1.5,
                                data: [
                                    {s: 0, e: 0.6, c: this.ok},
                                    {s: 0.6, e: 0.8, c: this.warning},
                                    {s: 0.8, e: 1.5, c: this.danger},
                                ],
                                gauge: this.getValueByTag('water_treatment_combined')
                            }} />

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReleveModal;