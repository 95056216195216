import React from 'react';
import equal from 'fast-deep-equal/react';
import $ from 'jquery';

import Loader from './Loader';
import SpanWithTooltip from './SpanWithTooltip';
import { getAPI } from '../utils';

class IndicatorsTableV2 extends React.Component {
    rowOne = React.createRef();
    rowTwo = React.createRef();
    rowThree = React.createRef();
    rowFour = React.createRef();

    state = {
        data: undefined,
        poolsData: undefined,
        sheetErrorMsg: undefined,
    }

    componentDidMount() {
        this._mounted = true;
        this.updateData();
        $(this.rowOne.current).tooltip();
        $(this.rowTwo.current).tooltip();
        $(this.rowThree.current).tooltip();
        $(this.rowFour.current).tooltip();
        $('[data-toggle="tooltip"]').tooltip();
    }

    componentDidUpdate = (prev) => {
        if (!equal(this.props.pool, prev.pool))
        {
            this.updateData();
        }
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    getIndicatorLast30NumbersDays(poolData) {
        if (poolData !== undefined) {
            const success = poolData.last30Days.success;
            const warning = poolData.last30Days.warning;
            const danger = poolData.last30Days.danger;
            const total = poolData.last30Days.total;
            let type = undefined;
            let title = `Nombre de relevés ces 30 derniers jours sur le bassin '${ poolData.name }'.<br/><br/>`;

            if (total >= 21) {
                type = 'success';
                title += ` Rien à signaler.`
            } else if (total >= 15) {
                type = 'warning';
                title += ` Attention: nombre de relevés insuffisant (${ total }/30).`
            } else {
                type = 'danger';
                title += ` Attention: nombre de relevés insuffisant (${ total }/30).`
            }
            let percentage = Math.min(Number.parseInt(100 * total / 30), 100);
            title += `<br/><br>Relevés normaux (${ success }), Relevés non conformes (${ warning }), Relevés à critiques (${ danger })`
            return <SpanWithTooltip title={ title } type={ type } message={ `${ percentage }%` } />
        }
        return ""
    }

    getIndicatorLast30Days(poolData) {
        if (poolData !== undefined) {
            const success = poolData.last30Days.success;
            const warning = poolData.last30Days.warning;
            const danger = poolData.last30Days.danger;
            const total = poolData.last30Days.total;
            const abnormal = warning + danger;
            let percentage = Math.min(Math.floor(total === 0 ? 0 : (abnormal * 100) / total), 100);
            let type = undefined;
            let title = `Nombre de relevés anormaux ces 30 derniers jours sur le bassin '${ poolData.name }'.<br/><br/>`;

            if (abnormal === 0) {
                type = 'success';
                title += ` Rien à signaler.`
            } else if (danger === 0) {
                type = 'warning';
                title += ` Attention: trop de relevés anormaux (${ percentage }%).`
            } else {
                type = 'danger';
                if (total !== 0) {
                    title += ` Attention: beaucoup trop de relevés anormaux (${ percentage }%).`
                } else {
                    title += ` Attention: pas de relevé trouvé pour ces deux derniers jours.`
                }
            }
            title += `<br/><br>Relevés normaux (${ success }), Relevés non conformes (${ warning }), Relevés à critiques (${ danger })`
            let message = total === 0 ? '-' : `${ percentage }%`;
            type = total === 0 ? undefined : type
            return <SpanWithTooltip title={ title } type={ type } message={ message } />
        }
        return "";
    }

    getIndicatorLast7Days(poolData) {
        if (poolData !== undefined) {
            const success = poolData.last7Days.success;
            const warning = poolData.last7Days.warning;
            const danger = poolData.last7Days.danger;
            const total = poolData.last7Days.total;
            const abnormal = warning + danger;
            const percentage = Math.min(Math.floor(total === 0 ? 0 : (abnormal * 100) / total) % 100, 100);
            let type = undefined;
            let title = `Nombre de relevés anormaux ces 7 derniers jours sur le bassin '${ poolData.name }'.<br/><br/>`;
            if (abnormal === 0) {
                type = 'success';
                title += ` Rien à signaler.`
            } else if (danger === 0) {
                type = 'warning';
                title += ` Attention: trop de relevés anormaux (${ percentage }%).`
            } else {
                type = 'danger';
                if (total !== 0) {
                    title += ` Attention: beaucoup trop de relevés anormaux (${ percentage }%).`
                } else {
                    title += ` Attention: pas de relevé trouvé pour ces deux derniers jours.`
                }
            }
            title += `<br/><br>Relevés normaux (${ success }), Relevés non conformes (${ warning }), Relevés à critiques (${ danger })`
            let message = total === 0 ? '-' : `${ percentage }%`;
            type = total === 0 ? undefined : type
            return <SpanWithTooltip title={ title } type={ type } message={ message } />
        }
        return "";
    }

    getIndicatorLastRecord(poolData) {
        let level = poolData.last.state;
        let type = undefined;
        let title = `Etat du dernier relevé sur le bassin '${ poolData.name }'.<br/><br/>`;
        let message = '';
        if (level === undefined) {
            type = 'secondary';
            title += ` Le dernier statut n'est pas connu.`;
            message = 'Inconnu';
        } else if (level === 0) {
            type = 'success';
            title += ` Rien à signaler.`;
            message = 'Conforme';
        } else if (level === 1) {
            type = 'warning';
            title += ` Attention: dernier relevé avec des données qui dépassent un seuil normal.`;
            message = 'Non conforme';
        } else {
            type = 'danger';
            title += ` Attention: dernier relevé avec des données qui dépassent le seuil dangereux.`;
            message = 'Critique';
        }
        return <SpanWithTooltip title={ title } type={ type } message={ message } />
    }

    updateData() {
        function initPoolName(name, status) {
            return {
                name: name,
                last30Days: {
                    total: 0,
                    success: 0,
                    anomaly: 0,
                    warning: 0,
                },
                last7Days: {
                    total: 0,
                    success: 0,
                    anomaly: 0,
                    warning: 0,
                },
                last: {
                    state: status,
                },
            }
        }

        if (this.props.poolId && this._mounted) {
            const endpoint = `/api/v2/projects/${ this.props.poolId }/stats/`;
            getAPI(this.props.host, endpoint, (err, response) => {
                if (err === false) {
                    if (response !== undefined && response.status === 200) {
                        if (response.data.length !== 0) {
                            let poolsData = {};
                            Object.values(response.data.pool_waterquality_last_month.data).forEach(entry => {
                                const poolName = entry.name;
                                if (poolName in poolsData === false) {
                                    poolsData[poolName] = initPoolName(poolName, entry.last_status);
                                }
                                poolsData[poolName].last30Days.total = entry.sheet;
                                poolsData[poolName].last30Days.success = entry.success;
                                poolsData[poolName].last30Days.warning = entry.warning;
                                poolsData[poolName].last30Days.danger = entry.danger;
                            });
                            Object.values(response.data.pool_waterquality_last_week.data).forEach(entry => {
                                const poolName = entry.name;
                                if (poolName in poolsData === false) {
                                    poolsData[poolName] = initPoolName(poolName, entry.last_status);
                                }
                                poolsData[poolName].last7Days.total = entry.sheet;
                                poolsData[poolName].last7Days.success = entry.success;
                                poolsData[poolName].last7Days.warning = entry.warning;
                                poolsData[poolName].last7Days.danger = entry.danger;
                            });
                            Object.values(response.data.pool_waterquality_last_day.data).forEach(entry => {
                                const poolName = entry.name;
                                if (poolName in poolsData === false) {
                                    poolsData[poolName] = initPoolName(poolName, entry.last_status);
                                }
                                poolsData[poolName].last.state = entry.last_status;
                            });
                            this.setState({
                                poolsData: Object.values(poolsData)
                            })
                        }
                    }
                }
            })
        }
    }

    getIndicatorsTable = () => {
        return <table className="w-100">
            <thead>
                <tr>
                    <th style={{ width: "250px" }}>Bassin</th>
                    <th className="text-center" ref={ this.rowOne } data-toggle="tooltip" title="Nombre de relevés ces 30 derniers jours sur le bassin.">Taux de remplissage</th>
                    <th className="text-center" colSpan={2}>Taux d'anomalies</th>
                    <th className="text-center" ref={ this.rowFour } data-toggle="tooltip" title="Etat du dernier relevé sur le bassin">Dernier relevé</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td></td>
                    <td></td>
                    <td className="text-center" ref={ this.rowTwo } data-toggle="tooltip" title="Nombre de relevés anormaux ces 30 derniers jours sur le bassin." >Depuis 7j</td>
                    <td className="text-center" ref={ this.rowThree } data-toggle="tooltip" title="Nombre de relevés anormaux ces 2 derniers jours sur le bassin." >Depuis 30j</td>
                    <td></td>
                </tr>
                {
                    this.state.poolsData.map((poolData, poolDataId) => {
                        return <tr key={ "pool-id-" + poolDataId }>
                            <td>
                                <SpanWithTooltip title='' message={ poolData.name } />
                            </td>
                            <td className="text-center">{ this.getIndicatorLast30NumbersDays(poolData) }</td>
                            <td className="text-center">{ this.getIndicatorLast7Days(poolData) }</td>
                            <td className="text-center">{ this.getIndicatorLast30Days(poolData) }</td>
                            <td className="text-center">{ this.getIndicatorLastRecord(poolData) }</td>
                        </tr>
                    })
                }
            </tbody>
        </table>
    }

    render() {
        if (this.state.sheetErrorMsg !== undefined) {
            return <div className="d-none d-md-block">{ this.state.sheetErrorMsg }</div>
        }
        if (this.state.poolsData === undefined) {
            return <Loader className=""/>
        }
        return (
            <React.Fragment>
                <div className="mb-3">
                    {
                        this.getIndicatorsTable()
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default IndicatorsTableV2;