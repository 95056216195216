import React from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortAlphaDown, faSortAlphaUp, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

import Navbar from '../Navbar';
import Footer from '../Footer';
import { getMarkerIconFromStatus } from '../../icons';

class IndexPage extends React.Component {
    state = {
        search: '',
        sort: 'alphaDown'
    }

    getPoolFromSearch() {
        if (this.state.search.trim().length) {
            try {
                const pattern = new RegExp(this.state.search, 'i');
                return this.props.pools.filter(pool => pool.name.match(pattern))
            } catch (err) {
            }
            return this.props.pools;
        }
        return this.props.pools;
    }

    sortPools(pools) {
        let sortAlgo = undefined;
        if (this.state.sort === 'alphaDown') {
            sortAlgo = (a, b) => {
                return a.name.localeCompare(b.name);
            }
        }
        if (this.state.sort === 'alphaUp') {
            sortAlgo = (a, b) => {
                return b.name.localeCompare(a.name);
            }
        }
        if (pools && sortAlgo) {
            return pools.sort(sortAlgo)
        }
        return pools;
    }

    handleSearchChange = (event) => {
        const search = event.target.value;
        this.setState({
            search: search,
        })
    }

    handleSortChange = (event) => {
        if (this.state.sort === 'alphaDown')
            this.setState({ sort: 'alphaUp' })
        if (this.state.sort === 'alphaUp')
            this.setState({ sort: 'alphaDown' })
    }

    getBounds() {
        if (!this.props.pools) {
            return undefined;
        }
        const bounds = this.props.pools.map(pool => [pool.latitude, pool.longitude]);
        if (bounds.length >= 2) {
            return bounds;
        }
        return undefined
    }

    getMap = () => {
        if (this.props.pools && this.props.pools.length === 0) {
            return <div></div>
        } else if (this.props.pools.length === 1) {
            const pool = this.props.pools[0];
            return <Map className="d-none d-md-block" style={{ height: 'calc(100vh - 10rem)' }} center={[pool.latitude, pool.longitude]} zoom={20} gestureHandling={true}>
                <TileLayer
                    // attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                    // url="https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw"
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                    onClick={() => this.props.handlePoolSelection(pool)}
                    position={[pool.latitude, pool.longitude]}
                    onMouseOver={(e) => e.target.openPopup()}
                    onMouseOut={(e) => e.target.closePopup()}
                    icon={getMarkerIconFromStatus(pool.status)}>
                    <Popup>
                        {`${pool.name}`}
                    </Popup>
                </Marker>
            </Map>
        } else {
            return <Map className="d-none d-md-block" style={{ height: 'calc(100vh - 10rem)' }} bounds={this.getBounds()} gestureHandling={true}>
                <TileLayer
                    // attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                    // url="https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw"
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {
                    this.props.pools && this.props.pools.map(pool => {
                        return (
                            <Marker
                                onClick={() => this.props.handlePoolSelection(pool)}
                                position={[pool.latitude, pool.longitude]}
                                onMouseOver={(e) => e.target.openPopup()}
                                onMouseOut={(e) => e.target.closePopup()}
                                icon={getMarkerIconFromStatus(pool.status)}
                                key={`marker-${pool.id}`}
                            >
                                <Popup>
                                    {`${pool.name}`}
                                </Popup>
                            </Marker>
                        );
                    })
                }
            </Map>
        }
    }

    getStatusMessage = (status) => {
        if (status === 'warning') {
            return <p className="warning">Indicateur(s) dans une zone d'alerte.</p>
        } else if (status === 'danger') {
            return <p className="danger">Indicateur(s) dans une zone dangereuse.</p>
        }
    }

    getStatusLogoMessage = (status) => {
        if (status === 'warning') {
            return <FontAwesomeIcon className="warning mr-2" title="Anomalies constatées" fixedWidth icon={faExclamationCircle} />
        } else if (status === 'danger') {
            return <FontAwesomeIcon className="danger mr-2" title="Anomalies constatées" fixedWidth icon={faExclamationCircle} />
        }
        return <span></span>
    }

    render() {
        return (
            <div className="container-fluid page">
                <Navbar showDataLegend={ true } />

                <div className="container-fluid row">
                    <div className="col-sm-9">
                        {
                            this.getMap()
                        }
                    </div>
                    <div className="col-sm-3">
                        <div className="input-group mb-4">
                            <button
                                className="btn btn-primary"
                                onClick={this.handleSortChange}
                            >
                                {this.state.sort === "alphaUp" && <FontAwesomeIcon fixedWidth icon={faSortAlphaUp} />}
                                {this.state.sort === "alphaDown" && <FontAwesomeIcon fixedWidth icon={faSortAlphaDown} />}
                            </button>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Recherche par nom de piscine"
                                value={this.state.search}
                                onChange={this.handleSearchChange}
                            />
                        </div>
                        <div className="pools overflow-auto">
                            {
                                this.props.pools && this.sortPools(this.getPoolFromSearch()).map(pool => {
                                    return (
                                        <div key={pool.id} onClick={() => this.props.handlePoolSelection(pool)} className="card p-3 c-pointer">
                                            <p>
                                                {pool.status !== undefined && this.getStatusLogoMessage(pool.status)}
                                                {pool.name}
                                            </p>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

export default IndexPage;